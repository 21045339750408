import React from 'react';
import DefaultLayout from '../layouts/DefaultLayout';

export default () => (
  <DefaultLayout
    bodyClass="thanks"
    pageTitle="Your email was sent"
    pageDescription=""
  >
    <div className="container">
      <section className="center">
        <h1 className="title">We appreciate your email.</h1>
        <p>We&rsquo;ll get back to you very soon!</p>
      </section>
    </div>
  </DefaultLayout>
);
